<template>
    <CompTable ref="comp_table" title="访问统计分析" :columns="getColumns()" :dataList="dataList" :dataBefore="onSubmitBefore" @on-reset="onReset">
        <template #search>
            <Select v-model="tableData.appInfoId" filterable style="width:200px" transfer @on-change="onChangeParents">
                <Option v-for="(item, idx) in parents" :key="idx" :value="item.appInfoId">{{ item.appName }}</Option>
            </Select>
            <Select v-model="tableData.childAppInfoId" filterable style="width:200px" transfer clearable placeholder="选择业务点">
                <Option v-for="(item, idx) in childs" :key="idx" :value="item.appInfoId">{{ item.appName }}</Option>
            </Select>
            <DatePicker v-model="tableData.year" type="year" placeholder="选择年" style="width: 200px" />
            <RadioGroup v-model="tableData.date" type="button" button-style="solid">
                <Radio :label="0">按年</Radio>
                <Radio :label="1">按月</Radio>
            </RadioGroup>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Request from "../jointly/utils/request"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            dateType: 1,
            tableData: {
                appInfoId: parseInt(this.$core.getUrlParam("i")),
                date: 1,
            },

            parents: [],
            childs: [],

            dataList: null,
        }
    },

    async created() {
        this.getDataList()
        this.parents = await this.getAppInfo()
        this.childs = this.parents.find(item => item.appInfoId === this.tableData.appInfoId)?.child
    },

    methods: {
        getColumns() {
            return [
                {
                    title: "年份",
                    key: "year",
                },
                ...(this.dateType === 1
                    ? [
                          {
                              title: "月份",
                              key: "month",
                          },
                      ]
                    : []),
                {
                    title: "访问次数",
                    key: "viewCount",
                },
                {
                    title: "访问人数",
                    key: "viewUserCount",
                },
                {
                    title: "排行",
                    render: (h, params) => {
                        return h("span", params.index + 1)
                    },
                },
            ]
        },

        getDataList() {
            const d = this.tableData
            Request.post(`/gateway/api/lfcommon/pc/appinfo/statView?appInfoId=${d.appInfoId}&childAppInfoId=${d.childAppInfoId || ""}&date=${d.date}&year=${(d.year instanceof Date ? d.year.getFullYear() : d.year) || ""}`).then(res => {
                this.dataList = res
                this.dateType = this.tableData.date
            })
        },

        getAppInfo() {
            return Request.get("/gateway/api/lfcommon/pc/appinfo/listAppInfo")
        },

        async onChangeParents(evt) {
            // 删除值
            this.tableData.childAppInfoId = ""
            // 获取子级
            this.childs = this.parents.find(item => item.appInfoId === evt)?.child
        },

        onSubmitBefore() {
            // 发起请求
            this.getDataList()
            // 返回false，阻止表单提交
            return false
        },

        onReset() {
            this.tableData = {
                appInfoId: parseInt(this.$core.getUrlParam("i")),
                date: 1,
            }
            this.getDataList()
        },
    },
}
</script>

<style lang="less">
.thirdparty-add {
    .attr-box {
        margin: 0 10px;
        padding: 20px 0;

        .name-box {
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;

            .name {
                padding: 0 10px;
                width: 200px;
                margin: 0 10px;
                flex-shrink: 0;
            }

            .value-box {
                width: 300px;
            }

            .operate-add {
                cursor: pointer;
                margin: 0 10px;
                padding: 0 15px;
                font-size: 20px;
                border-radius: 4px;
                background: #6fc3f8;
            }
        }

        .item-box {
            margin-bottom: 10px;
            display: flex;

            .item-form {
                display: flex;
                flex-shrink: 0;
            }

            .item {
                margin: 0 10px;

                .module {
                    width: 200px;

                    &.input {
                        height: 32px;
                        line-height: 1.5;
                        padding: 4px 7px;
                        font-size: 14px;
                        border: 1px solid #dcdee2;
                        border-radius: 4px;
                        color: #515a6e;
                        background-color: #fff;
                    }
                }

                .range-box {
                    width: 200px;

                    .range-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .range {
                            width: 90px;
                        }
                    }
                }

                &.value-box {
                    width: 300px;

                    .module {
                        width: 300px;
                    }

                    .range-box {
                        width: 300px;

                        .range-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .range {
                                width: 140px;
                            }
                        }
                    }
                }
            }

            .operate {
                margin: 0 10px;

                .operate-item {
                    cursor: pointer;
                    padding: 0 15px;
                    font-size: 20px;
                    border-radius: 4px;

                    &.add {
                        background: #6fc3f8;
                    }

                    &.remove {
                        background: #f88662;
                    }
                }
            }
        }
    }
}
</style>
